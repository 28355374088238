<template>
  <div class="country-selector"
       v-click-outside="closeDropdown">
    <div class="selected-value" @click="clickOpen">
      <div v-if="oldSelectedTotal === this.countriesTotal || oldSelectedTotal === 0">
        Worldwide ({{ this.countriesTotal }})
      </div>
      <div v-else-if="oldSelectedTotal === 1">
        <dynamic-image classes="country-flag"
                       :width="14"
                       :height="10"
                       :country-code="Object.keys(this.oldSelected)[0]"/>
        {{ this.oldSelected[Object.keys(this.oldSelected)[0]] }}
      </div>
      <div v-else class="countries">
        <div v-for="group in preparedForTitle">
          <span>{{ group.group }} ({{ group.total }})</span>
          <dynamic-image classes="country-flag"
                         :width="14"
                         :height="10"
                         v-for="country in group.countries"
                         :country-code="country.code"/>
        </div>
      </div>
      <svg-icon icon="angle-down-solid" class="cursor-pointer" :class="{rotate180: opened}"/>
    </div>
    <div class="container" v-if="opened">
      <div class="search-input-wrap">
        <basic-search-input placeholder="Search countries"
                            :hide-results-block="true"
                            :clear-input="searchInput"
                            @search-input-changed="searchInputChanged">
          <template v-slot:search-icon>
            <svg-icon v-if="searchInput?.length === 0"
                      icon="search-solid"/>
            <svg-icon v-else
                      @click="clearSearch"
                      class="close-icon"
                      icon="close"/>
          </template>
        </basic-search-input>
        <div class="selected-container">
          <svg-icon icon="check-alt"/>
          <div>Selected: {{ formattedSelectedTotal }}</div>
        </div>
      </div>

      <div class="countries-block">
        <div class="results-wrap">
          <div class="results">
            <div class="results-group">
              <div class="group-header">
                <div class="group-title">Worldwide</div>
                <div class="group-selector">
                  <div class="select-group">
                    <div v-if="isAllSelected" @click="deselectWorldwide">
                      Deselect Worldwide
                    </div>
                    <div v-else @click="selectWorldwide">
                      Select Worldwide
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="filteredCountries.length === 0" class="empty-search-result">
              No data found
            </div>
            <div v-for="group in filteredCountries" class="results-group">
              <div class="group-header">
                <div class="group-title">
                  {{ group.group }}
                  <svg-icon icon="angle-down-solid" class="cursor-pointer"
                            :class="{rotate180: !group.hidden}"
                            @click="hideGroup(group)"/>
                </div>
                <div class="group-selector">
                  <div class="selected-container" v-if="selectedGroupTotals[group.group] > 0">
                    <svg-icon icon="check-alt"/>
                    <div v-if="selectedGroupTotals[group.group] === group.countries.length">
                      Selected All
                    </div>
                    <div v-else>
                      Selected: {{ selectedGroupTotals[group.group] }}
                    </div>
                  </div>
                  <div class="select-group">
                    <div v-if="selectedGroupTotals[group.group] === group.countries.length"
                         @click="deselectGroup(group, $event)">
                      Deselect {{ group.group }}
                    </div>
                    <div v-else @click="selectGroup(group, $event)">
                      Select {{ group.group }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="countries" v-if="!group.hidden">
                <div v-for="country in group.countries"
                     @click="clickCountry(country.code, country.name)"
                     class="country"
                     :class="{'selected': selected[country.code] !== undefined}">
                  <span class="count" v-if="country.count">({{ country.count }})</span>
                  <span class="name">
                    <dynamic-image classes="country-flag"
                                   :width="14"
                                   :height="10"
                                   :country-code="country.code"/>
                    {{ country.name }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="selected-wrap">
          <div class="selected">
            <div v-if="selectedTotal > 0" class="group-selector" @click="deselectWorldwide">
              Deselect all
            </div>
            <div v-else class="empty-selected">
              Select countries from the list on the left
              <br>
              ⬅️ ⬅️ ⬅️
            </div>

            <div class="countries">
              <div v-for="country in filteredSelected">
                <div class="country">
                  <span class="count" v-if="country.count">({{ country.count }})</span>
                  <span class="name">
                      <dynamic-image classes="country-flag"
                                     :width="14"
                                     :height="10"
                                     :country-code="country.code"/>
                      {{ country.name }}
                    </span>
                </div>
                <svg-icon icon="close-alt" @click="removeCountry(country.code, $event)"/>
              </div>
            </div>

            <div class="empty-selected" v-if="selectedTotal === 1">
              <div>
                Select more countries from the list on the left
                <br>
                ⬅️ ⬅️ ⬅️
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="buttons">
        <base-button mode="outline"
                     font-size="14px"
                     @click="closeDropdown"
                     height="36px"
                     width="120px">
          Cancel
        </base-button>
        <base-button @click="applyChanges"
                     font-size="14px"
                     height="36px"
                     width="120px">
          Apply ({{ formattedSelectedTotal }})
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import CustomInlineSearch from "@/components/Forms/CustomInlineSearch/index.vue";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import SearchInput from "@/components/UI/SearchInput/index.vue";
import BaseButton from "@/components/UI/BaseButton/index.vue";
import BasicSearchInput from "@/components/UI/BasicSearchInput/index.vue";
import {mapGetters} from "vuex";

export default {
  name: 'CountrySelector',
  components: {
    BasicSearchInput,
    BaseButton,
    SearchInput,
    SvgIcon,
    CustomInlineSearch
  },
  props: {
    allCountries: Array,
    selectedOuter: Array,
    returnAllCountries: {
      type: Boolean,
      default: false, // якщо true, то при виборі всіх країн буде повертатись список кодів, якщо false - пустий масив
    },
  },
  data() {
    return {
      countries: this.allCountries,
      opened: false,
      selected: {},
      oldSelected: {},
      searchInput: '',
      plansModalShowed: false,
    }
  },
  mounted() {
    this.processSelectedOuter();
  },
  methods: {
    searchInputChanged(inputValue) {
      this.searchInput = inputValue;
    },
    clearSearch(event) {
      event.stopPropagation();

      this.searchInput = '';
    },
    closeDropdown() {
      if (this.plansModalShowed) {
        this.plansModalShowed = false;
      } else {
        this.opened = false;
        this.searchInput = '';
        this.selected = {...this.oldSelected};
      }
    },
    clickOpen() {
      this.opened = !this.opened;
      if (this.opened) {
        this.oldSelected = {...this.selected};
      } else {
        this.selected = this.oldSelected;
      }
    },
    clickCountry(code, title) {
      if (this.selected[code] === undefined) {
        if (Object.keys(this.selected).length < this.countriesLimit) {
          this.selected[code] = title;
        } else {
          this.openPlansModal();
        }
      } else {
        delete this.selected[code];
      }
    },
    removeCountry(code, event) {
      event.stopPropagation();

      delete this.selected[code];
    },
    applyChanges() {
      if (Object.keys(this.selected).length > 0) {
        this.opened = false;
        this.oldSelected = {...this.selected};
        this.$emit('changed', this.isAllSelected && !this.returnAllCountries ? [] : Object.keys(this.selected), this.isAllSelected);
      }
    },
    deselectWorldwide(event) {
      event.stopPropagation();

      this.selected = {};
    },
    selectWorldwide(event, showModal = true) {
      event?.stopPropagation();

      let selected = {};
      let openPlansModal = false;
      this.countries.forEach((group) => {
        group.countries.forEach((country) => {
          if (Object.keys(selected).length < this.countriesLimit) {
            selected[country.code] = country.name;
          } else {
            openPlansModal = true;
          }
        });
      });

      this.selected = selected;
      if (openPlansModal && showModal !== false) {
        this.openPlansModal();
      }
    },
    selectGroup(group, event) {
      event.stopPropagation();

      let selected = this.selected;
      let openPlansModal = false;
      group.countries.forEach((country) => {
        if (Object.keys(selected).length < this.countriesLimit) {
          selected[country.code] = country.name;
        } else {
          openPlansModal = true;
        }
      });

      this.selected = {...selected};
      if (openPlansModal) {
        this.openPlansModal();
      }
    },
    deselectGroup(group, event) {
      event.stopPropagation();

      let selected = this.selected;
      group.countries.forEach((country) => {
        if (selected[country.code] !== undefined) {
          delete selected[country.code];
        }
      });
      this.selected = {...selected};
    },
    hideGroup(group) {
      let hidden = group.hidden === undefined ? true : !group.hidden;
      let countries = this.countries;
      countries.forEach((item) => {
        if (group.group === item.group) {
          item.hidden = hidden;
        }
      });

      this.countries = countries;
    },
    openPlansModal() {
      this.plansModalShowed = true;
      this.$store.dispatch('INVOKE_USER_LIMITS_MANUAL');
    },
    processSelectedOuter() {
      if (this.selectedOuter.length === 0) {
        this.selectWorldwide(null, false);
      } else {
        let selected = {};
        this.countries.forEach((group) => {
          group.countries.forEach((country) => {
            if (this.selectedOuter.includes(country.code)) {
              selected[country.code] = country.name;
            }
          });
        });
        this.selected = selected;
      }
      this.oldSelected = this.selected;
    },
  },
  computed: {
    ...mapGetters([
      'userSubscription',
    ]),
    isFreeUser() {
      return this.userSubscription?.plan_name === 'Free';
    },
    countriesLimit() {
      return this.isFreeUser ? 5 : 999;
    },
    selectedTotal() {
      return Object.keys(this.selected).length;
    },
    formattedSelectedTotal() {
      let total = this.selectedTotal;
      if (this.isAllSelected) {
        total = 'All, ' + total;
      }

      return total;
    },
    countriesTotal() {
      let total = 0;
      this.countries.forEach((group) => {
        if (group.group !== 'Popular') {
          total += group.countries.length;
        }
      });

      return total;
    },
    isAllSelected() {
      return this.selectedTotal === this.countriesTotal;
    },
    filteredCountries() {
      let list = [];
      let searchInput = this.searchInput.toLowerCase();
      this.countries.forEach((group) => {
        let countries = group.countries.filter((country) => country.name.toLowerCase().includes(searchInput));
        if (countries.length > 0) {
          list.push({
            group: group.group,
            hidden: group.hidden,
            countries: countries,
          });
        }
      });

      return list;
    },
    filteredSelected() {
      let list = [];
      let searchInput = this.searchInput.toLowerCase();
      this.countries.forEach((group) => {
        if (group.group !== 'Popular') {
          let countries = group.countries.filter((country) => this.selected[country.code] !== undefined && country.name.toLowerCase().includes(searchInput));
          list = list.concat(countries);
        }
      });

      return list.sort((a, b) => a.name > b.name ? 1 : -1);
    },
    preparedForTitle() {
      let list = [];
      this.countries.forEach((group) => {
        let countries = group.countries.filter((country) => this.oldSelected[country.code] !== undefined);
        if (countries.length > 0 && group.group !== 'Popular') {
          let total = 0;
          for (let country of group.countries) {
            if (this.oldSelected[country.code] !== undefined) {
              total++;
            }
          }

          list.push({
            group: group.group,
            total: total,
            countries: countries,
          });
        }
      });

      return list;
    },
    selectedGroupTotals() {
      let groups = {};
      this.countries.forEach((group) => {
        groups[group.group] = 0;
        for (let country of group.countries) {
          if (this.selected[country.code] !== undefined) {
            groups[group.group]++;
          }
        }
      });

      return groups;
    },
    oldSelectedTotal() {
      return Object.keys(this.oldSelected).length;
    },
  },
  watch: {
    selectedOuter() {
      this.processSelectedOuter();
    },
    allCountries() {
      this.countries = this.allCountries;
    },
  },
}
</script>

<style src="./styles.scss" lang="scss"></style>
